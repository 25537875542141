import styled from 'styled-components';
import { ReactNode } from 'react';
import Footer from './Footer';
import Nav from './Nav/Navbar';
import { BREAK_POINTS } from './styles/Variables';


type Props = {
  disableWrapperStyle: boolean;
  children?: ReactNode;
};

export default function Layout({ children, disableWrapperStyle }: Props): JSX.Element {
  return (
    <>
      <BodyWrapper>
        <Nav />
        {children}
      </BodyWrapper>
      <Footer />
    </>
  );
}

const BodyWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    flex-direction: column;
  }
`;