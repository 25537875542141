import styled from 'styled-components';
import Colors from '../common/styles/Colors';
import { BREAK_POINTS } from '../common/styles/Variables';

import Logo from '../../images/ic_logo-white.png';


export default function Footer(): JSX.Element {
  return (
    <FooterWrapper>
      <TextLink href="/">
        <LogoImg src={Logo} />
      </TextLink>
      <CopyRight>©{year} Futurize, Inc. All Rights Reserved.</CopyRight>
    </FooterWrapper>
  );
}

const d = new Date();
const year = d.getFullYear();


const FooterWrapper = styled.footer`
  padding: 8.7rem 0 7rem;
  text-align: center;
  background-color: ${Colors.SUB};
  @media (max-width: ${BREAK_POINTS.TAB}) {
    padding: 5rem 0;
  }
`;

const LogoImg = styled.img`
  width: 17rem;
  display: block;
  margin: 0 auto;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    width: 14rem;
  }
`;

const CopyRight = styled.p`
  color: ${Colors.WHITE100};
  font-size: 1.2rem;
  padding-top: 4rem;
  letter-spacing: 1px;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    padding-top: 2rem;
    font-size: 1.1rem;
  }
`;

const TextLink = styled.a``;