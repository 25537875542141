import React from 'react';
import styled from 'styled-components';
import Colors from '../common/styles/Colors';
import { BREAK_POINTS } from '../common/styles/Variables';
import { Section, SectionInner } from '../common/Section';
import { SectionTitle } from '../common/Title';
import { Btn } from '../common/Btn';
import WorkItem from '../landing/WorkItem';
import MemberItem from '../landing/MemberItem';
import RecruitItem from '../landing/RecruitItem';
import WelfareItem from '../landing/WelfareItem';

import client from '../../client';

import HeroPc from '../../images/img_hero-pc.jpg';
import HeroSp from '../../images/img_hero-sp.jpg';

function sortContent(content1, content2) {
  if (content1.fields.order > content2.fields.order) {
    return 1;
  } else if (content1.fields.order < content2.fields.order) {
    return -1;
  }
  return 0;
}

class Landing extends React.Component {  
  state = {
    works: [],
    members: [],
    recruitInfo: [],
    welfareInfo: [],
  }

  componentDidMount() {
    client.getEntries().then(response => {
      const items = response.items;
      const works = items.filter(item => item.sys.contentType.sys.id === 'works').sort(sortContent);
      const members = items.filter(item => item.sys.contentType.sys.id === 'member').sort(sortContent);
      const recruitInfo = items.filter(item => item.sys.contentType.sys.id === 'recruit').reverse();
      const welfareInfo = response.items.filter(item => item.sys.contentType.sys.id === 'welfare').sort(sortContent);
      this.setState({
        members,
        works,
        recruitInfo,
        welfareInfo
      })
    })
  }


  render() {
    const { works, members, recruitInfo, welfareInfo } = this.state;
    return (
      <Main id="top">
        <Hero>
          <HeroImgPc src={HeroPc} />
          <HeroImgSp src={HeroSp} />
        </Hero>
        <Section id="message">
          <SectionTitle>Message</SectionTitle>
          <SectionInner>
            <SubTitle>
              クリエイションで世界の「面白い」を<NoIndention>増やす</NoIndention>
            </SubTitle>
            <Text>
              IT技術者はプログラミングやデザインなど「モノを作ること」自体が仕事だと思われがちですが、わたしたちが作るデジタルサービスの本質的価値とは、時代に即した最適な技術によってどこかの誰かの生活にポジティブな変化をもたらすことにこそあります。
            </Text>
            <Text>
              単なる動画配信サービスではなく、<br />いつも忙しいお父さんが家族と週末映画を見て過ごす幸せな時間を。
            </Text>
            <Text>
              単なるSNSではなく、<br />上京して間もない社会人の孤独を癒やす人とのつながりを。
            </Text>
            <Text>
              単なるメディアではなく、<br />内気な大学生が初めてのともだちを作るきっかけを。
            </Text>
            <Text>
              Futurizeのプロダクト開発は、そうやってプロダクトの先にいる「誰か」の人生を最大限豊かにすることができるプロダクトの最適な形を追求し続けることにあります。エンジニアやデザイナーなどの役割のボーダーを超えて、それぞれがサービスリリースの直前までユーザーに対して徹底的に共感し、寄り添いながら開発をおこないます。
            </Text>
          </SectionInner>
        </Section>

        {/* <Section>
          <SectionTitle>Clients</SectionTitle>
          <SectionInner>
            <ClientsList>
              {clients.map((client) => (
                <ClientItem key={client.fields.clientName} client={client} />
              ))}
            </ClientsList>
          </SectionInner>
        </Section> */}

        <Section id="works">
          <SectionTitle>Works</SectionTitle>
          <SectionInner>
            <WorksList>
            {works.map((work) => (
              <WorkItem key={work.fields.worksSlug} work={work}/>
            ))}
            </WorksList>
          </SectionInner>
        </Section>

        <Section id="member">
          <SectionTitle>
            Member
          </SectionTitle>
          <SectionInner>
            <MemberList>
              {members.map((member) => (
                <MemberItem key={member.fields.memberName} member={member} />
              ))}
            </MemberList>
          </SectionInner>
        </Section>

        <Section id="recruit">
          <SectionTitle>Recruit</SectionTitle>
          <SectionInner>
            <RecruitText>
              Futurizeでは、プロダクト開発が大好きなデザイナー、エンジニア、ディレクターを募集しています。
            </RecruitText>

            <Dl>
              {recruitInfo.map((recruit) => (
                <RecruitItem key={recruit.fields.recruitTitle} recruit={recruit} />
              ))}
            </Dl>
            <Btn to="/contact">Contact</Btn>
          </SectionInner>
        </Section>

        <Section>
          <SectionTitle>Welfare</SectionTitle>
          <SectionInner>
            <Dl>
              {welfareInfo.map((welfare) => (
                <WelfareItem key={welfare.fields.welfareTitle} welfare={welfare} />
              ))}
            </Dl>
            <Btn to="/contact">Contact</Btn>
          </SectionInner>
        </Section>
        <Section id="company">
          <SectionTitle>Company</SectionTitle>
          <SectionInner>
          <Map src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3238.694798511277!2d139.7082471152887!3d35.733723834698594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d58d51c399f%3A0x40f128d37ed56774!2z5qCq5byP5Lya56S-RnV0dXJpemU!5e0!3m2!1sja!2sjp!4v1631784736829!5m2!1sja!2sjp"></Map>
          <CompanyInfo>
            <Table>
              <Tbody>
                <Tr>
                  <Th>会社名</Th>
                  <Td>株式会社Futurize</Td>
                </Tr>
                <Tr>
                  <Th>所在地</Th>
                  <Td>東京都豊島区池袋2丁目46-3 CIMA100ビル 4F</Td>
                </Tr>
                <Tr>
                  <Th>設立</Th>
                  <Td>2016年3月1日</Td>
                </Tr>
                <Tr>
                  <Th>代表者</Th>
                  <Td>代表取締役  瀧嶋篤志</Td>
                </Tr>
                <Tr>
                  <Th>関連会社</Th>
                  <Td><TextLink href="https://minto-inc.jp/" target="_blank" rel="noopener noreferrer">株式会社Minto</TextLink>
                  <TextDetail>※2023年に<SpanInline>株式会社Futurize</SpanInline>は、<SpanInline>株式会社Minto</SpanInline>のグループ会社となりました。</TextDetail>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </CompanyInfo>
          </SectionInner>
        </Section>
      </Main>
    );
  }
}

export default Landing


const Main = styled.main`
  padding-bottom: 9rem;
  width: calc(100% - 360px);
  @media (max-width: ${BREAK_POINTS.TAB}) {
    width: 100%;
  }
`;

const NoIndention = styled.span`
  display: inline-block;
`


// hero
const Hero = styled.div`
  padding-top: 9rem;
  margin-bottom: 7rem;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    margin-left: 3rem;
    padding-top: 0;
  }
  @media (max-width: ${BREAK_POINTS.SP}) {
    margin-left: 1.8rem;
  }
`;
const HeroImgPc = styled.img`
  border-radius: 20px 0 0 20px;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    display: none;
  }
`;

const HeroImgSp = styled.img`
  display: none;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    display: block;
    width: 100%;
    border-radius: 10px 0 0 10px;
  }
`;

const Text = styled.p`
  color: ${Colors.BLACK54};
  &:not(:nth-last-of-type(1)) {
    padding-bottom: 1.25rem;
  }
`;

const SubTitle = styled.h3 `
  font-size: 2.2rem;
  // color: ${Colors.SUB};
  margin-bottom: 2rem;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    font-size: 1.7rem;
    margin-bottom: 1.2rem;
  }
`


// Client
const ClientsList = styled.ul`
  display: flex;  
  flex-wrap: wrap;
  margin-top: -1.6rem;
`;


// Works
const WorksList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    gap: 2.4rem;
  }
`;


// Member
const MemberList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 30px 5%;
  @media (max-width: ${BREAK_POINTS.SMALL}) {
    gap: 24px 4%;
  }
`;

// Recruit
const RecruitText = styled.p`
  font-size: 1.7rem;
  margin-bottom: 16px;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    font-size: 1.5rem;
    margin-bottom: 24px;
  }
  @media (max-width: ${BREAK_POINTS.SP}) {
    font-size: 1.4rem;
  }
`;
const Dl = styled.dl``;


// Company
const Map = styled.iframe`
  filter: grayscale(40%);
  width: 100%;
  height: 500px;
  position: relative;
  z-index: -1;
  @media (max-width: ${BREAK_POINTS.SP}) {
    height: 230px;
  }
`;

const CompanyInfo = styled.div`
  margin-top: 30px;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    margin-top: 12px;
  }
`;
const Table = styled.table`
  width: 100%;
`;
const Tbody = styled.tbody``;
const Tr = styled.tr``;
const Th = styled.th`
  width: 22%;
  font-weight: 500;
  padding: 6px 0;
  vertical-align: text-top;
`;
const Td = styled.td`
  padding: 6px 0;
`;
const TextLink = styled.a`
  border-bottom: 1px solid ${Colors.BLACK87};
`;
const TextDetail = styled.p`
  font-size: 1.4rem;
  margin-top: 4px;
  color: ${Colors.BLACK54};
  line-height: 1.8;
  @media (max-width: ${BREAK_POINTS.SP}) {
    font-size: 1.3rem;
  }
`;
const SpanInline = styled.span `
  display: inline-block;
`