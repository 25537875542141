const Colors = {
  BLACK87: 'rgba(57,54,54,.87)',
  BLACK54: 'rgba(0,0,0,0.54)',
  BLACK26: 'rgba(0,0,0,0.26)',
  BLACK12: 'rgba(0,0,0,0.12)',
  //white
  WHITE100: '#FFFFFF',
  WHITE70: 'rgba(255,255,255,0.70)',
  WHITE30: 'rgba(255,255,255,0.30)',
  WHITE12: 'rgba(255,255,255,0.12)',

  //ui
  GRAY: '#A8A8AF',
  ACCENT: '#F87A52',
  SUB: '#938D89',
  BG: '#FAF7F4',
};

export default Colors;
