import { useState } from 'react';
import styled from 'styled-components';
import { BREAK_POINTS } from '../styles/Variables';
import Colors from '../styles/Colors';
import Burger from '../Nav/Burger';
import { HashLink } from 'react-router-hash-link';

import Logo from '../../../images/ic_logo.png';


const Nav = () => {
  const [open, setOpen] = useState(false)

  const scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth"
    });
  }

  const onClickLogo = () => {
    setOpen(false)
  }

  return (
    <NavWrapper>
      <NavInner>
        <HashLink to="/#top" scroll={el => scrollWithOffset(el, 70)} onClick={onClickLogo}>
          <LogoImg src={Logo} />
        </HashLink>
        <Burger open={open} setOpen={setOpen} />
      </NavInner>
    </NavWrapper>
  );
}

export default Nav


const NavWrapper = styled.aside`
  width: 360px;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: ${Colors.BG};
  @media (max-width: ${BREAK_POINTS.TAB}) {
    width: 100%;
    height: inherit;
    background-color: ${Colors.BG};
  }
`;

const NavInner = styled.div`
  transform: translateY(-10px);
  @media (max-width: ${BREAK_POINTS.TAB}) {
    transform: translateY(0);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: inherit;
    padding: 2.4rem 1.6rem;
  }
`;

const LogoImg = styled.img`
  width: 160px;
  display: block;
  position: relative;
  z-index: 999;
`;