import styled from 'styled-components';
import Menu from './Menu';
import Colors from '../../common/styles/Colors';

const Burger = ({ open, setOpen }) => {
  return (
    <>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <span />
        <span />
        <span />
      </StyledBurger>
      <Menu open={open} onClickMenu={() => { setOpen(false)}}/>
    </>
  )
}

export default Burger



const StyledBurger = styled.div`
  position: relative;
  width: 2.3rem;
  height: 1.7rem;
  z-index: 20;
  display: none;

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }

  span {
    width: 100%;
    height: .2rem;
    background-color: ${Colors.BLACK87};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    position: absolute;

    &:nth-child(1) {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
      top: 0;
    }

    &:nth-child(2) {
      opacity: ${({ open }) => open ? 0 : 1};
      top: .8rem;
    }

    &:nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
      top: calc(100% - 2px);
    }
  }
`;