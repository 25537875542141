import styled from 'styled-components';
import { BREAK_POINTS } from '../common/styles/Variables';
import Colors from './styles/Colors';
import Fonts from './styles/Fonts';

export const SectionTitle = styled.h2`
  font-size: 2.8rem;
  font-family: ${Fonts.EN};
  font-weight: 600;
  line-height: 1.6;
  color: ${Colors.ACCENT};
  @media (max-width: ${BREAK_POINTS.SMALL}) {
    margin-bottom: 1.5rem;
  }
  @media (max-width: ${BREAK_POINTS.TAB}) {
    font-size: 2.6rem;
  }
`;

export const PageTitle = styled.h2`
  padding-bottom: 3rem;
  font-size: 3.6rem;
  font-family: ${Fonts.EN};
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 1.4;
  color: ${Colors.ACCENT};
  @media (max-width: ${BREAK_POINTS.TAB}) {
    padding-bottom: .6rem;
    font-size: 2.6rem;
  }
`;