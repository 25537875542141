import styled from 'styled-components';
import Colors from './styles/Colors';
import { BREAK_POINTS } from './styles/Variables';
import { Link } from 'react-router-dom';


export const Btn = styled(Link)`
  display: inline-block;
  margin-top: 3.8rem;
  padding: 1.6rem 6.4rem 1.6rem;
  font-size: 1.6rem;
  font-weight: 600;
  color: ${Colors.WHITE100};
  background: ${Colors.SUB};
  border-radius: 6px;
  line-height: 1.4;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    margin-top: 2rem;
    font-size: 1.4rem;
    margin-left: 16px;
  }
`;

export const SendBtn  = styled.button`
  display: block;
  margin-top: 3.8rem;
  margin-left: auto;
  padding: 1.6rem 6.4rem 1.6rem;
  font-size: 1.6rem;
  font-weight: 600;
  color: ${Colors.WHITE100};
  background: ${Colors.SUB};
  border-radius: 6px;
  line-height: 1.4;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    font-size: 1.4rem;
    margin: 2rem auto 0;
  }
`;