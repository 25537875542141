import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Landing from './components/landing/Landing';
import WorksDetail from './components/works/WorksDetail';
import Layout from './components/common/Layout';
import Contact from './components/contact/Contact';
import client from './client';
import Thanks from './components/contact/Thanks';
import { Breadcrumbs } from './components/common/Breadcrumbs';


class App extends React.Component {
  state = {
    works: []
  }

  componentDidMount() {
    client.getEntries({
      content_type :'works'
    }).then((response) => {      
      this.setState({
        works: response.items
      })
    });
  }  

  render(){
    const { works } = this.state;
    const breadcrumbs = <Breadcrumbs works={works} />
    return (
      <Layout>
        <Switch>
          <Route path="/" exact component={Landing} />
          {works.map(work => (
            <Route path={`/${work.fields.worksSlug}`} exact component={() => <WorksDetail work={work} breadcrumbs={breadcrumbs} />} key={work.fields.worksSlug} />
          ))}          
          <Route path="/contact" exact component={() => <Contact breadcrumbs={breadcrumbs} />} />
          <Route path="/thanks" exact component={Thanks} />
        </Switch>
      </Layout>
    );
  }
}

export default App;
