import React from 'react';
import styled from 'styled-components';
import { BREAK_POINTS } from '../styles/Variables';
import Colors from '../styles/Colors';

import { HashLink } from 'react-router-hash-link';
import Fonts from '../styles/Fonts';

const Menu = ({ open, onClickMenu }) => {
  const scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth"
    });
  }
  return (
    <NavListWrapper open={open}>
      <NavList>
        <NavItem>
          <NavLink to="/#message" scroll={el => scrollWithOffset(el, 70)} onClick={onClickMenu}>Message</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/#works" scroll={el => scrollWithOffset(el, 70)} onClick={onClickMenu}>Works</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/#recruit" scroll={el => scrollWithOffset(el, 70)} onClick={onClickMenu}>Recruit</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/#company" scroll={el => scrollWithOffset(el, 70)} onClick={onClickMenu}>Company</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/contact" onClick={onClickMenu}>Contact</NavLink>
        </NavItem>
      </NavList>
    </NavListWrapper>
  )
}

export default Menu

const NavListWrapper = styled.nav`
  margin-top: 6rem;
  margin-left: 1.2rem;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    background-color: ${Colors.BG};
    position: fixed;
    transform: ${({ open }) => open ? 'translateY(0)' : 'translateY(-100%)'};
    opacity: ${({ open }) => open ? 1 : 0};
    visibility: ${({ open }) => open ? 'visible' : 'hidden'};
    top: 0;
    left: 0;
    margin-left: 0;
    height: 100vh;
    width: 100vw;
    margin: 0;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const NavList = styled.ul``;
const NavItem = styled.li`
  @media (max-width: ${BREAK_POINTS.TAB}) {
    text-align: left;
  }
`;
const NavLink = styled(HashLink)`
  font-size: 2.1rem;
  padding: 1.6rem 0;
  display: block;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 2px;
  cursor: pointer;
  color: ${Colors.SUB};
  font-family: ${Fonts.EN};
  @media (max-width: ${BREAK_POINTS.TAB}) {
    padding: 2.5rem 0;
    // font-size: 1.8rem;
  }
`;