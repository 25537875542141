import styled from 'styled-components';
import Colors from '../common/styles/Colors';
import { BREAK_POINTS } from '../common/styles/Variables';
import { Link } from 'react-router-dom';

const WorkItem = ({work}) => {
  return (
    <WorksItemWrapper>
      {/* <WorksLink to={`/${work.fields.worksSlug}`}> */}
        <WorksItem>
          <WorksImgWrapper>
            <WorksImg src={work.fields.worksThumb.fields.file.url} alt={work.fields.wirksName} />
          </WorksImgWrapper>
          <WorksTextWrapper>
            <WorksTitle>{work.fields.worksName}</WorksTitle>
            <WorksDesc>{work.fields.worksSkill}</WorksDesc>
          </WorksTextWrapper>
        </WorksItem>
      {/* </WorksLink> */}
    </WorksItemWrapper>
  )
}

export default WorkItem


const WorksItemWrapper = styled.li``;
const WorksItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    flex-direction: column;
  }
`;
// const WorksLink = styled(Link)``;
const WorksImgWrapper = styled.figure`
  width: 60%;
  flex-shrink: 0;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    width: 100%;
  }
`;
const WorksImg = styled.img`
  width: 100%;
  display: block;
  border-radius: 6px;
`;
const WorksTextWrapper = styled.div`
  padding-left: 4rem;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    padding-left: 0;
    padding-top: 1.4rem;
  }
`;
const WorksTitle = styled.p`
  font-weight: 600;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    font-size: 1.5rem;
    text-align: center;
  }
`;
const WorksDesc = styled.p`
  font-size: 1.4rem;
  color: ${Colors.GRAY};
  @media (max-width: ${BREAK_POINTS.TAB}) {
    font-size: 1.2rem;
  }
`;