export const WIDTH = {
  MAX: 1120,
  BASE: 1056,
  SIDE: 32,
};

export const SP_WIDTH = {
  BASE: 780,
  SIDE: 4.4, //%
};

export const BREAK_POINTS = {
  SMALL: '1214px',
  TAB: '780px',
  SP: '480px'
}