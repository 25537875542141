import { createGlobalStyle } from 'styled-components';
import Colors from './Colors';
import Fonts from './Fonts';
import { BREAK_POINTS } from './Variables'

export default createGlobalStyle`


* {
  margin: 0;
  padding: 0;
  &::selection {
    background-color: rgba(#193, 175, 255, .6);
  }
}

html {
  box-sizing: border-box;
  color: ${Colors.BLACK87};
  font-family: ${Fonts.JP};
  font-size: 62.5%;
  font-feature-settings: "palt";
  background-color: ${Colors.BG};

  * {
    box-sizing: inherit;
    -webkit-tap-highlight-color: rgba(193, 175, 255, .6);

    &:before,
    &:after {
      box-sizing: border-box;
    }
  }
}

body {
  -webkit-text-size-adjust: 100%;
  width: 100%;
  height: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.2;
  letter-spacing: .08em;
  min-height: 100vh;
  position: relative;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
  @media only screen and(-webkit-min-device-pixel-ratio: 2),(min-resolution: 2dppx) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @media (max-width: ${BREAK_POINTS.TAB}) {
    font-size: 1.5rem;
    // line-height: 2.4;
  }
  @media (max-width: ${BREAK_POINTS.SP}) {
    font-size: 1.4rem;
  }
}

article,
aside,
figure,
footer,
header,
section {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: 700;
  letter-spacing: .08em;
}

p {
  line-height: 2.2;
  font-size: 1.6rem;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    font-size: 1.4rem;
    // line-height: 1.8;
  }
}

ul {
  list-style:none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

table {
  border-collapse:collapse;
  border-spacing:0;
}

th,
td {
  padding: 0;
  text-align: left;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  opacity: .8;
}

input, button, textarea, select {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outs: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 1.6rem;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    font-size: 1.3rem;
  }
}

button {
  cursor: pointer;
  &:hover {
    opacity: .8;
  }
}

iframe {
  border: none;
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
}


p



`;
