import React from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import styled from 'styled-components';
import { BREAK_POINTS } from './styles/Variables';
import { Link } from 'react-router-dom';

import Arrow from '../../images/ic_chevron_right.svg';
import Colors from './styles/Colors';
import Fonts from './styles/Fonts';

export const Breadcrumbs = ({ works }) => {
  const routes = works.map(work => (
    { path: `/${work.fields.worksSlug}`, breadcrumb: work.fields.worksName }
  ))  
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <BreadcrumbsWrapper>
      <React.Fragment>
        {breadcrumbs.map(({ breadcrumb, match }) => (
          <Link to={match.path} key={match.path}>
            {breadcrumb}
          </Link>
        ))}
      </React.Fragment>
    </BreadcrumbsWrapper>
  );
}


const BreadcrumbsWrapper = styled.div`
  margin-bottom: 3.5rem;
  font-size: 1.5rem;
  font-family: ${Fonts.EN};
  font-weight: 500;
  // text-transform: uppercase;
  color: ${Colors.GRAY};
  @media (max-width: ${BREAK_POINTS.TAB}) {
    margin-bottom: 4rem;
    font-size: 1rem;
  }

  a:not(:last-child):after {
    content: '';
    display: inline-block;
    width: .6rem;
    height: .9rem;
    margin: 0 1rem;
    background-image: url(${Arrow});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;

    @media (max-width: ${BREAK_POINTS.TAB}) {
      width: .5rem;
      height: .7rem;
    }
  }
`;