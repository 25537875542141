import React from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { BREAK_POINTS } from '../common/styles/Variables';

import { PageTitle } from '../common/Title';
import { SendBtn } from '../common/Btn';
import Colors from '../common/styles/Colors';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: "", email: "", message: "" };
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }
  encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

  handleSubmit = (event) => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: this.encode({ 'form-name': 'contact', ...this.state })
    })
    .then(() => {
      this.props.history.push('/thanks')
    })
    .catch(error => alert(error));

    event.preventDefault();
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { breadcrumbs } = this.props;
    const { name, email, message } = this.state;
    return (
      <Main>
        <Container>
          <PageTitle>Contact</PageTitle>
          {breadcrumbs}
          <Text>
            アイデアをお持ちですか？ プロダクト開発・デザインのご相談など、お気軽にお問い合わせください。<br />
            また、一緒に働きたいデザイナー、エンジニア、ディレクターの方からのご連絡もお待ちしています。
          </Text>

          <Form onSubmit={this.handleSubmit}>
            <input type="hidden" name="form-name" value="contact" />
            <FormItem>
              <Label htmlFor="name">名前</Label>
              <Input type="text" id="name" name="name" value={name} placeholder="山田太郎" required onChange={this.handleChange} />
            </FormItem>
            <FormItem>
              <Label htmlFor="name">メールアドレス</Label>
              <Input type="text" id="email" name="email" value={email} placeholder="inquiry@futurize.jp" required onChange={this.handleChange} />
            </FormItem>
            <FormItem>
              <Label htmlFor="message">お問い合わせ内容</Label>
              <Textarea id="message" name="message" value={message} placeholder="お問い合わせ内容を入力" required onChange={this.handleChange} />
            </FormItem>
            <SendBtn type="submit">送信</SendBtn>
          </Form>
        </Container>
      </Main>
    );
  }
}

export default withRouter(Contact);


const Main = styled.main`
  padding-bottom: 9rem;
  width: calc(100% - 360px);
  @media (max-width: ${BREAK_POINTS.TAB}) {
    width: 100%;
  }
`;

const Text = styled.p`
  padding-bottom: 5rem;
  font-size: 1.6rem;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    font-size: 1.3rem;
    padding-bottom: 3.6rem;
    line-height: 2.4;
  }
`;

const Container = styled.div`
  padding: 10.4rem 0 9rem;
  margin: 0 auto;
  max-width: 920px;
  width: 100%;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    padding: 3rem 1.6rem 9rem;
    max-width: inherit;    
  }
`;

const Form = styled.form``;
const FormItem = styled.div`
  margin-bottom: 5rem;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    margin-bottom: 4rem;
  }
  &:not(:nth-last-of-type(1)) {
    margin-bottom: 3rem;
    @media (max-width: ${BREAK_POINTS.TAB}) {
      margin-bottom: 2.7rem;
    }
  }
`;

const Label = styled.label`
  display: block;
  padding-bottom: 2rem;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    font-size: 1.4rem;
    padding-bottom: 1.9rem;
  }
`;

const Input = styled.input`
  padding: 1.8rem 2.8rem 1.8rem;
  font-size: 1.6rem;
  background: rgba(0, 0, 0, 0.03);
  letter-spacing: .08em;
  border-radius: 6px;
  width: 100%;
  &:focus {
    outline: 0;
    border: 2px solid ${Colors.BLACK12};
  }
  &::placeholder {
    color: rgba(0, 0, 0, 0.26);
  }
  @media (max-width: ${BREAK_POINTS.TAB}) {
    padding: 2rem;
    font-size: 1.4rem;
  }
`;

const Textarea = styled.textarea`
  padding: 1.4rem 2.8rem 2.2rem;
  border-radius: 1px;
  width: 100%;
  background: rgba(0, 0, 0, 0.03);
  height: 25.4rem;
  font-size: 1.6rem;
  letter-spacing: .08em;
  border-radius: 6px;
  &:focus {
    outline: 0;
    border: 2px solid ${Colors.BLACK12};
  }
  &::placeholder {
    color: rgba(0, 0, 0, 0.26);
  }
  @media (max-width: ${BREAK_POINTS.TAB}) {
    padding: 1.8rem 2rem 2.2rem;
    font-size: 1.4rem;
  }
`;