import styled from 'styled-components';
import { BREAK_POINTS } from './styles/Variables';

export const Section = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  max-width: 920px;
  margin-bottom: 9rem;
  &:last-child {
    margin-bottom: 0;
  }
  @media (max-width: ${BREAK_POINTS.SMALL}) {
    flex-direction: column;
    margin-bottom: 8rem;
    padding-left: 1.8rem;
  }
  @media (max-width: ${BREAK_POINTS.TAB}) {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  @media (max-width: ${BREAK_POINTS.SP}) {
    padding-right: 1.8rem;
    padding-left: 1.8rem;
  }
`;

export const WorksSection = styled(Section)`
  margin-bottom: 5rem;
`;

export const SectionInner = styled.div`
  max-width: 734px;
  width: 100%;
  padding-right: 4rem;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    padding-right: 0;
  }
`;

export const SectionInnerText = styled(SectionInner) `
  @media (max-width: ${BREAK_POINTS.SMALL}) {
    padding-left: 3.2rem;
  }
  @media (max-width: ${BREAK_POINTS.TAB}) {
    padding-left: 1.8rem;
  }
`;