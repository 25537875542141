import React from 'react';
import styled from 'styled-components';
import Colors from '../common/styles/Colors';
import { BREAK_POINTS } from '../common/styles/Variables';

const RecruitItem = ({recruit}) => {
  return (
    <>
      <Dt>
        {recruit.fields.recruitTitle}
      </Dt>
      <Dd>
        {recruit.fields.recruitDesc}
      </Dd>
    </>
  )
}

export default RecruitItem


const Dt = styled.dt`
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  font-size: 1.7rem;
  &:before {
    content: '';
    width: 12px;
    height: 12px;
    background-color: ${Colors.ACCENT};
    display: block;
    border-radius: 100%;
    margin-right: 8px;
    flex-shrink: 0;
    margin-top: 13px;
  }
  @media (max-width: ${BREAK_POINTS.TAB}) {
    font-size: 1.4rem;
    line-height: 2;
    margin-bottom: 4px;
    &:before {
      width: 8px;
      height: 8px;
      margin-top: 10px;
    }
  }
`;
const Dd = styled.dd`
  color: ${Colors.BLACK54};
  margin-bottom: 16px;
  margin-left: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  @media (max-width: ${BREAK_POINTS.TAB}) {
    margin-bottom: 20px;
    margin-left: 16px;
  }
  @media (max-width: ${BREAK_POINTS.SP}) {
    font-size: 1.3rem;
  }
`;
