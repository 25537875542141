import styled from 'styled-components';
import Colors from '../common/styles/Colors';
import { BREAK_POINTS } from '../common/styles/Variables';
import Twitter from '../../images/ic_twitter.png';
import Note from '../../images/ic_note.png';
import Qiita from '../../images/ic_qiita.png';

const MemberItem = ({member}) => {
  return (
    <MemberItemWrapper>
      <MemberImg src={member.fields.memberImg.fields.file.url} />
      <MemberInfo>
        <MemberPosition>{member.fields.memberPosition}</MemberPosition>
        <MemberName>{member.fields.memberName}</MemberName>
        <MemberSnsWrapper>
          
          {member.fields.memberTwitter ? (
            <MemberSnsIcWrapper>
              <MemberSnsLink href={member.fields.memberTwitter} target="_blank" rel="noopener noreferrer">
                <MemberSnsIc src={Twitter} />
              </MemberSnsLink>
            </MemberSnsIcWrapper>
          ) : (
            null
          )}

          {member.fields.memberNote ? (
            <MemberSnsIcWrapper>
              <MemberSnsLink href={member.fields.memberNote} target="_blank" rel="noopener noreferrer">
                <MemberSnsIc src={Note} />
              </MemberSnsLink>
            </MemberSnsIcWrapper>
          ) : (
            null
          )}

          {member.fields.memberQiita ? (
            <MemberSnsIcWrapper>
              <MemberSnsLink href={member.fields.memberQiita} target="_blank" rel="noopener noreferrer">
                <MemberSnsIc src={Qiita} />
              </MemberSnsLink>
            </MemberSnsIcWrapper>
          ) : (
            null
          )}
        </MemberSnsWrapper>
      </MemberInfo>
    </MemberItemWrapper>
  )
}

export default MemberItem



const MemberItemWrapper = styled.li`
  width: 30%;
  @media (max-width: ${BREAK_POINTS.SMALL}) {
    width: 48%;
  }
`;
const MemberImg = styled.img`
  border-radius: 6px;
`;
const MemberInfo = styled.div`
  margin-top: 16px;
`;
const MemberPosition = styled.p`
  font-size: 1.3rem;
  color: ${Colors.GRAY};
  line-height: 1;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    font-size: 1.2rem;
  }
`;
const MemberName = styled.p`
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 12px;
  line-height: 1;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    font-size: 1.3rem;
  }
`;
const MemberSnsWrapper = styled.ul`
  display: flex;
  margin-top: 14px;
`;
const MemberSnsIcWrapper = styled.li`
  width: 18px;
  margin-right: 12px;
`;
const MemberSnsLink = styled.a``;
const MemberSnsIc = styled.img`
  display: block;
`;


