import styled from 'styled-components';
import { BREAK_POINTS } from '../common/styles/Variables';

import { PageTitle } from '../common/Title';
import { Btn } from '../common/Btn';

export default function Thanks() {
  return (
    <Main>
      <Container>
        <PageTitle>Thank you</PageTitle>
        <Text>
        お問い合わせありがとうございます。<br />内容によってはご連絡差し上げられない場合もございます、予めご了承ください。
        </Text>
        <Btn to="/#top">トップページに戻る</Btn>
      </Container>
    </Main>
  );
}


const Main = styled.main`
  padding-bottom: 9rem;
  width: calc(100% - 360px);
  @media (max-width: ${BREAK_POINTS.TAB}) {
    width: 100%;
  }
`;

const Text = styled.p`
  font-size: 1.8rem;
  margin-bottom: 3rem;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    font-size: 1.3rem;
    padding-bottom: 3.6rem;
    line-height: 2.4;
    margin-bottom: 0;
  }
`;

const Container = styled.div`
  padding: 29.7rem 0 9rem;
  margin: 0 auto;
  max-width: 920px;
  width: 100%;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    padding: 10rem 1.6rem 9rem;
    max-width: inherit;    
  }
`;
