import React from 'react';
import styled from 'styled-components';
import { BREAK_POINTS } from '../common/styles/Variables';
import { WorksSection, SectionInnerText } from '../common/Section';
import { SectionTitle } from '../common/Title';

function WorksDetail({ work, breadcrumbs }) {
  // const location = useLocation();
  // const [work, setWork] = useState();
  // useEffect (() => {
  //   getWork();
  // },[]);
  //
  // const getWork = () => {
  //   client.getEntries({
  //     content_type :'works'
  //   })
  //   .then((response) => {
  //     const foundWork = response.items.find((item) => {
  //       return `/${item.fields.worksSlug}` === location.pathname;
  //     })      
  //     setWork(foundWork)
  //   })
  // };


  return (
    <Main>
      <Hero>
        <HeroImgPc src={work?.fields?.worksHeroPc?.fields?.file?.url} alt={work?.fields?.worksName} />
        <HeroImgSp src={work?.fields?.worksHeroSp?.fields?.file?.url} alt={work?.fields?.worksName} />
      </Hero>
      
      <BreadcrumbsWrapper>
        {breadcrumbs}
      </BreadcrumbsWrapper>

      <Contents>

        <WorksSection>
          <SectionTitle>Back ground</SectionTitle>
          <SectionInnerText>
            {work?.fields?.worksBackground.split("\n").map((background) =>
              <Text key={background}>
                {background}
              </Text>
            )}
          </SectionInnerText>
        </WorksSection>

        <WorksSection>
          <SectionTitle>Process</SectionTitle>
          <SectionInnerText>
            {work?.fields?.worksProcess.split("\n").map((process) =>
              <Text key={process}>
                {process}
              </Text>
            )}
          </SectionInnerText>
        </WorksSection>

        <WorksSection>
          <SectionTitle>Result</SectionTitle>
          <SectionInnerText>
            {work?.fields?.worksResult.split("\n").map((result) =>
              <Text key={result}>
                {result}
              </Text>
            )}
          </SectionInnerText>
        </WorksSection>
      </Contents>
    </Main>
  )
}

export default WorksDetail;


const Main = styled.main`
  padding-bottom: 9rem;
  width: calc(100% - 360px);
  @media (max-width: ${BREAK_POINTS.TAB}) {
    width: 100%;
  }
`;

const Text = styled.p`
  margin-bottom: 16px;
`;

const Hero = styled.div`
  padding-top: 9rem;
  margin-bottom: 2.4rem;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    margin-left: 0;
    padding-top: 0;
    margin-bottom: 1.2rem;
  }
`;
const HeroImgPc = styled.img`
  @media (max-width: ${BREAK_POINTS.TAB}) {
    display: none;
  }
`;

const HeroImgSp = styled.img`
  display: none;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    display: block;
  }
`;

const Contents = styled.div`
  margin-top: 7.5rem;
  @media (max-width: ${BREAK_POINTS.TAB}) {
    margin-top: 0;
  }
`;

const BreadcrumbsWrapper = styled.div`
  @media (max-width: ${BREAK_POINTS.TAB}) {
    margin: 0 1.8rem;
  }
`;